import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import ContactForm from '../components/ContactForm';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Row mdxType="Row">
      <Column colMd={2} colLg={5} className="bx--type-expressive-heading-03" mdxType="Column">
        <p>{`Do you need technology or a new concept for the business? `}</p>
        <p>{`Contact us and we’ll help you plan your route. Our team will contact you for more information about your requirements and a detailed analysis of your needs. `}</p>
        <p>{`We will use your information to make a detailed evaluation proposal available to you.`}</p>
      </Column>
      <Column colMd={2} colLg={5} offsetMd={1} offsetLg={1} mdxType="Column">
Please fill out the form below and let us talk to you about your idea. You will be kept confidential with your details.
        <br />
        <ContactForm mdxType="ContactForm" />
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      